import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { faUser, faLock } from '@fortawesome/pro-solid-svg-icons';

// Modules
import { deparam } from '../../../../../modules/global';
import Window from '../../../../../modules/window';

// Models
import Opportunity from '../../../../../models/v2/opportunity';
import User from '../../../../../models/v2/user';

// Base
import Button from '../../../base/button';
import Col from '../../../base/col';
import Row from '../../../base/row';

// Form
import Checkbox from '../../../form/checkbox';
import Input from '../../../form/input';

// Related Components
import ForgotPassword from './sign_in/forgot_password';
import ResendConfirmation from './sign_in/resend_confirmation';
import Google from './google';
import UserType from './sign_up/user_type';

export default class SignIn extends React.Component {
  static propTypes = {
    domain: PropTypes.string,
    forgotPassword: PropTypes.bool,
    opportunityId: PropTypes.number,
    onSignIn: PropTypes.func,
  }

  static defaultProps = {
    forgotPassword: false,
  }

  constructor(props) {
    super(props);

    const params = deparam(location.search.slice(1));
    this.state = {
      forgotPassword: props.forgotPassword,
      resendConfirmation: false,
      sending: false,
      user: {
        email: params.email,
      },
      showUserType: false,
    }
  }

  toggleForgotPassword = () => {
    const { forgotPassword } = this.state;
    this.setState({ forgotPassword: !forgotPassword });
  }

  toggleResendConfirmation = () => {
    const { resendConfirmation } = this.state;
    this.setState({ resendConfirmation: !resendConfirmation});
  }

  handleInputChange = (field, value) => {
    const { user } = this.state;
    const newUser = update(user, { [field]: { $set: value } });
    this.setState({ user: newUser });
  }

  handleSubmit = async (e) => {
    const { domain, opportunityId, onSignIn } = this.props;
    const { sending, user } = this.state;

    e && e.preventDefault();

    if (sending) return;

    // Prep a window if we've been given an opportunity id
    // prevents popup block
    let myWindow;
    let opportunity = {};
    if (opportunityId) {
      opportunity = await Opportunity.find(opportunityId);
      if (opportunity.is_jobspikr) {
        const winId = (new Date()).getTime();
        myWindow = window.open('', winId)
      }
    }

    this.setState({ sending: true }, () => {
      $.ajax({
        url: domain + '/users/sign_in.json',
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          user,
          opportunity_id: opportunityId,
        }),
        success: (response) => {
          const user = response.user;
          const location = response.location;
          if (onSignIn == undefined) {
            if (domain) {
              // If we have a domain, this is hosted on a 3rd party page.
              // Use OTT url to ensure the user is logged in
              Window.redirect(response.ott_location);
            } else {
              Window.redirect(location);
            }
          } else {
            onSignIn(location, user, myWindow);
          }
        },
        error: (errors) => {
          const { responseJSON } = errors;
          const message = (responseJSON && responseJSON.error) ? responseJSON.error : 'Invalid email or password';
          Window.flash.error(errors, message);
          this.setState({
            sending: false
          }, () => {
            if (opportunityId) {
              myWindow.close();
            }
          });
        },
      });
    })
  }

  handleGoogleAuth = (response) => {
    const { user } = response;
    if (user && !user.user_type) {
      this.setState({
        showUserType: true,
        user: response.user,
        response,
      })
    } else {
      this.handleRedirect(response.location);
    }
  }

  // Redirect to either the requested location or back to the root path
  handleRedirect = (location) => {
    const { response } = this.state;
    // Take what's passed in, if not, then what's stored in state
    // and finally fall back to root path
    const redirectTo = location || (response || {}).location || '/';
    Window.redirect(redirectTo);
  }

  handleChangeUserType = (userType) => {
    const { user } = this.state;
    const newUser = update(user, { $merge: { user_type: userType } });
    this.setState({ user: newUser });
  }

  handleNext = () => {
    const { user } = this.state;
    const data = {
      id: user.id,
      user_type: user.user_type,
    };
    (new User(data))
      .save()
      .then(() => this.handleRedirect())
      .catch ((error) => Window.flash.error(error));
  }

  render() {
    const { user, forgotPassword, resendConfirmation, showUserType, sending } = this.state;

    if(showUserType) {
      return <UserType {...{
        user,
        onChangeUserType: this.handleChangeUserType,
        onNext: this.handleNext,
      }} />
    }

    if (forgotPassword) {
      return <ForgotPassword
        onComplete={this.toggleForgotPassword}
        onCancel={this.toggleForgotPassword}
      />
    }

    if (resendConfirmation) {
      return <ResendConfirmation
        onComplete={this.toggleResendConfirmation}
        onCancel={this.toggleResendConfirmation}
      />
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <Row noMargin>
          <Col s={12}>
            <Input {...{
              id: 'user_email',
              icon: faUser,
              placeholder: 'Email',
              type: 'email',
              onChange: (_, value) => this.handleInputChange('email', value),
              defaultValue: user.email,
            }} />
          </Col>
          <Col s={12}>
            <Input {...{
              id: 'user_password',
              icon: faLock,
              placeholder: 'Password',
              type: 'password',
              onChange: (_, value) => this.handleInputChange('password', value),
              allowPasswordToggle: true,
            }} />
          </Col>
          <Col s={12}>
            <div className='flex margin-bottom-10'>
              <div className='flex-grow-1'>
                <Checkbox {...{
                  id: 'user_remember_me',
                  label: 'Remember me',
                  onChange: (_, checked) => this.handleInputChange('remeber_me', checked),
                }} />
              </div>
              <div className='right-align'>
                <a
                  id='forgot_password'
                  className='primary-color hover-dark-primary'
                  onClick={this.toggleForgotPassword}
                >Forgot Password?</a>
                <br />
                <a
                  id='resend_confirmation'
                  className='primary-color hover-dark-primary'
                  onClick={this.toggleResendConfirmation}
                >Resend Confirmation?</a>
              </div>
            </div>
          </Col>
          <Col s={12}>
            <Button
              id='sign_in'
              text='Sign In'
              onClick={this.handleSubmit}
              disabled={sending}
              block
            />
          </Col>
        </Row>
        <Row className='margin-top-10 margin-bottom-10'>
          <Col s={12} className='vertical-align justify-center'>
            OR
          </Col>
        </Row>
        <Row className='margin-top-10'>
          <Col s={12}>
            <div className='margin-center width-400'>
              <Google {...{
                onAuth: this.handleGoogleAuth,
              }} />
            </div>
          </Col>
        </Row>
        <button type='submit' className='hide' />
      </form>
    )
  }
}